import { FC, ReactNode, useEffect, useState } from 'react';
import './styles.scss';
import { getCl } from '../../helper';
import { NavLink } from 'react-router-dom';
import Image from '../image/image';
import AppStore, { IFooterMenu } from '../../store/appStore';
import { observer } from 'mobx-react-lite';

interface Props {
    active: boolean,
}

export const Footer: FC<Props> = observer((props: Props) => {

    return (
        <footer className={`footer ${getCl(props.active, 'active')}`}>
            <div className='container'>
                <div className='footer__inner'>
                    <div className='footer__row'>
                        <div className='footer__col'>
                            <NavLink to={'/'} className='logoFull'>
                                <Image src='logoFull.svg' />
                            </NavLink>
                        </div>
                        <div className='footer__col'>
                            <div className='footer__menu'>
                                {AppStore.footer.map(element => {
                                    return <FooterSection key={element.id} element={element} />
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='footer__row'>
                        <div className='footer__col'>
                            <NavLink to={'/'} className='logoExt'>
                                <Image src='5pub_logo.svg' />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
})

interface FooterSectionProps {
    element: IFooterMenu
}

export const FooterSection: FC<FooterSectionProps> = (props: FooterSectionProps) => {
    return (
        <div className='footer__section'>
            <div className='footer__section_title'>
                {props.element.title}
            </div>
            <div className='footer__section_list'>
                {props.element.links.map(link => {
                    return (
                        <NavLink key={link.id} to={link.link} className='footer__section_item'>
                            {link.title}
                        </NavLink>
                    )
                })}
            </div>
        </div>
    )
}